import { Link } from "react-router-dom";
import {
  Dropdown,
  Icon,
  Label,
  Placeholder,
  PlaceholderLine,
  SemanticCOLORS,
} from "semantic-ui-react";
import useCourseColor from "../../../../../hooks/useCourseColor";
import useCurrentUserCourseRole from "../../../../../hooks/useCurrentUserCourseRole";
import useWindowSize from "../../../../../hooks/useWindowSize";
import { CalendarEntry, CalendarEntryContentType } from "../../../../../models/CalendarEntry";
import { CalendarEntryLink } from "../../../../../models/CalendarEntryLink";
import Color from "../../../../../models/Color";
import { useStore } from "../../../../../stores/store";
import { formatDateOnlyHoursAndMinutes } from "../../../../../utilities/dateTimeUtils";
import { createClassName, isQuillEmpty } from "../../../../../utilities/utils";
import CardTypeLabel from "../../../../_common/cards/_common/CardTypeLabel";
import FlexContainer from "../../../../_common/style/FlexContainer";
import RoundedSegment from "../../../../_common/style/RoundedSegment";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import UserInputDisplay from "../../../../_common/textEditing/UserInputDisplay";
import CreateOrUpdateCalendarEntryModal from "../_modal/CreateOrUpdateCalendarEntryModal";
import "./CalendarEntryCard.css";

interface CalendarEntryLinkCardProps {
  link: CalendarEntryLink;
}

export const CalendarEntryCardSkeleton: React.FC = () => (
  <div className="CalendarEntryCardSkeleton">
    <CardTypeLabel content="Event" />
    <Placeholder>
      <PlaceholderLine />
      <PlaceholderLine />
      <PlaceholderLine />
    </Placeholder>
  </div>
);

const CalendarEntryLinkCard: React.FC<CalendarEntryLinkCardProps> = ({ link }) => {
  if (link.isLocalRoute) {
    return (
      <Link to={link.link} target="_blank" className="calendar-entry-link" role={"button"}>
        <Icon name={link.calendarLinkType?.icon} />
        <span>{link.calendarLinkType?.name}</span>
        <Icon name="chevron right" className="chevron" />
      </Link>
    );
  }

  return (
    <a
      href={link.link}
      target="_blank"
      rel="noreferrer"
      className="calendar-entry-link"
      role={"button"}
    >
      <Icon name={link.calendarLinkType?.icon} />
      <span>{link.calendarLinkType?.name}</span>
      <Icon name="chevron right" className="chevron" />
    </a>
  );
};

interface CalendarEntryCardProps {
  calendarEntry: CalendarEntry;
}

const CalendarEntryCard: React.FC<CalendarEntryCardProps> = ({ calendarEntry }) => {
  const { isMobile } = useWindowSize();
  const { courseStore, modalStore, calendarStore, toastStore } = useStore();
  const { course } = courseStore;
  const { deleteCalendarEntry } = calendarStore;
  const { showToast } = toastStore;
  const courseColor = useCourseColor(Color.GREY);
  const { isUserTeachingTeam } = useCurrentUserCourseRole(calendarEntry.courseID);

  const handleDeleteCalendarEntry = async () => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the calendar entry, "${calendarEntry.title}"?`
    );
    if (confirmDelete) {
      const success = await deleteCalendarEntry(calendarEntry);
      if (success) {
        showToast(`Calendar Entry "${calendarEntry.title}" was successfully deleted`);
      } else {
        showToast(`We were unable to delete Calendar Entry "${calendarEntry.title}".`, {
          color: "red",
        });
      }
    }
  };

  const label =
    calendarEntry.calendarEntryTypes.length > 0
      ? `${calendarEntry.calendarEntryTypes.map(({ name }) => name).join(", ")}: ${
          calendarEntry.title
        }`
      : calendarEntry.title;

  let time =
    calendarEntry.startTime === calendarEntry.endTime
      ? formatDateOnlyHoursAndMinutes(calendarEntry.startTime)
      : `${formatDateOnlyHoursAndMinutes(
          calendarEntry.startTime
        )} - ${formatDateOnlyHoursAndMinutes(calendarEntry.endTime)}`;

  if (calendarEntry.calendarEntryContentType) {
    switch (calendarEntry.calendarEntryContentType) {
      case CalendarEntryContentType.ASSIGNMENT_RELEASE_DATE:
        time = `Released at ${time}`;
        break;
      case CalendarEntryContentType.ASSIGNMENT_DUE_DATE:
        time = `Due at ${time}`;
        break;
      case CalendarEntryContentType.ASSIGNMENT_RESUBMISSION_DUE_DATE:
        time = `Resubmissions due at ${time}`;
        break;
      default:
        break;
    }
  }

  const shouldAddMenu =
    isUserTeachingTeam &&
    (calendarEntry.calendarEntryContentType === undefined ||
      calendarEntry.calendarEntryContentType === CalendarEntryContentType.CALENDAR_ENTRY);

  return (
    <RoundedSegment
      key={calendarEntry.id}
      className={createClassName("CalendarEntryCard", { name: "mobile", apply: isMobile })}
    >
      <CardTypeLabel content="Event" />
      <div className="grid-section-a">
        <Label
          ribbon
          content={label}
          color={
            (calendarEntry.calendarEntryTypes.length > 0
              ? calendarEntry.calendarEntryTypes[0]?.color ?? courseColor
              : courseColor) as SemanticCOLORS
          }
          data-print-id="color"
          size="large"
        />
        <VerticalGap height="1em" />
        <Icon name="clock outline" /> <span className="time">{time}</span>
        <br />
        {calendarEntry.location && (
          <>
            <Icon name="map outline" /> {calendarEntry.location}
            <br />
          </>
        )}
        {calendarEntry.description && !isQuillEmpty(calendarEntry.description) && (
          <div className="description">
            <Icon name="file alternate outline" />{" "}
            <UserInputDisplay content={calendarEntry.description} />
          </div>
        )}
        <VerticalGap height="1em" />
        <FlexContainer gap="0.5em" flexDirection="row" flexWrap="wrap">
          {calendarEntry.calendarEntryTypes.map(({ id, name, color }) => (
            <Label
              content={name}
              key={id}
              tag
              color={(color ?? course?.color ?? Color.GREY) as SemanticCOLORS}
              data-print-id={"color"}
            />
          ))}
          {calendarEntry.isRequired && <div className="entry-card-label required">Required</div>}
          {calendarEntry.isCanceled && <div className="entry-card-label canceled">Canceled</div>}
        </FlexContainer>
      </div>
      <div data-print-id="hide">
        <FlexContainer
          className={createClassName("calendar-entry-time grid-section-b", {
            name: "teaching-team",
            apply: shouldAddMenu,
          })}
          gap="0.33rem"
          alignItems="end"
          flexDirection="column"
        >
          {shouldAddMenu && (
            <>
              <Dropdown
                className="options"
                icon={"ellipsis vertical"}
                options={[
                  {
                    key: "edit",
                    content: "Edit Calendar Entry",
                    icon: "edit",
                    onClick: () => {
                      modalStore.openModal(
                        <CreateOrUpdateCalendarEntryModal
                          calendarEntryToEdit={calendarEntry}
                          courseID={calendarEntry.courseID}
                        />
                      );
                    },
                  },
                  {
                    key: "delete",
                    content: "Delete Calendar Entry",
                    icon: "trash",
                    onClick: handleDeleteCalendarEntry,
                  },
                ]}
                direction="left"
              />
            </>
          )}
          {calendarEntry.links
            .filter((link) => !!link.calendarLinkType)
            .map((link) => (
              <CalendarEntryLinkCard
                link={link}
                key={`${link.calendarEntryID}-${link.calendarLinkTypeID}`}
              />
            ))}
        </FlexContainer>
      </div>
    </RoundedSegment>
  );
};

export default CalendarEntryCard;

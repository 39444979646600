import { Field, Form, Formik, FormikErrors } from "formik";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button, Checkbox, Container, Header, Label, SemanticCOLORS } from "semantic-ui-react";
import useBooleanState from "../../../../../hooks/useBooleanState";
import { CalendarEntry } from "../../../../../models/CalendarEntry";
import { CalendarEntryLink } from "../../../../../models/CalendarEntryLink";
import { CalendarEntryType } from "../../../../../models/CalendarEntryType";
import Color, { getRandomColor } from "../../../../../models/Color";
import { useStore } from "../../../../../stores/store";
import { objectSize } from "../../../../../utilities/collectionUtils";
import {
  DayOfTheWeek,
  createRecurringCalendarEntries,
} from "../../../../../utilities/dateTimeUtils";
import {
  FormikSetFieldValueFunction,
  FormikSetValuesFunction,
  isFormDirty,
} from "../../../../../utilities/formUtils";
import LoadingComponent from "../../../../../utilities/routing/components/LoadingComponent";
import { emptyID } from "../../../../../utilities/submissionUtils";
import ColorPickerInput from "../../../../_common/form/ColorPickerInput";
import FormDateInput from "../../../../_common/form/FormDateInput";
import FormLogoInput from "../../../../_common/form/FormLogoInput";
import FormRichTextInput from "../../../../_common/form/FormRichTextInput";
import FlexContainer from "../../../../_common/style/FlexContainer";
import VerticalGap from "../../../../_common/style/spacing/VerticalGap";
import "./CreateOrUpdateCalendarEntryModal.css";
import CalendarEntryLinkEditor from "./_common/CalendarEntryLinkEditor";
import CalendarEntryRecurrenceEditor from "./_common/CalendarEntryRecurrenceEditor";

interface CreateOrUpdateCalendarEntryModalProps {
  calendarEntryToEdit?: CalendarEntry;
  courseID: string;
}

export type CalendarEntryModalFormValues = {
  dayOfCalendarEntry: Date | undefined;
  startTime: Date | undefined;
  endTime: Date | undefined;
  recurUntilDate: Date | undefined;
  title: string;
  description: string;
  location: string;
  isCanceled: boolean;
  isRequired: boolean;
  isRecurring: boolean;
  calendarEntryTypes: string[]; // IDs of CalendarEntryTypes
  calendarEntryLinks: CalendarEntryLink[];
  newEventTypeName: string;
  newEventTypeColor: Color;
  newLink: string;
  daysToRecur: DayOfTheWeek[];
};

const CreateOrUpdateCalendarEntryModal: React.FC<CreateOrUpdateCalendarEntryModalProps> = ({
  calendarEntryToEdit,
  courseID,
}) => {
  const { modalStore, calendarStore, toastStore, courseStore } = useStore();
  const {
    calendarEntryTypes,
    loadCalendarEntryTypes,
    hasLoadedCalendarEntryTypesForCourse,
    createOrUpdateCalendarEntryType,
    createOrUpdateCalendarEntries,
  } = calendarStore;
  const { course } = courseStore;
  const [isAddingNewEventType, setAddingNewEventType] = useBooleanState();
  const [isSubmittingNewEventType, setIsSubmittingNewEventType] = useBooleanState();
  const [isSubmittingForm, setIsSubmittingForm] = useBooleanState();

  useEffect(() => {
    loadCalendarEntryTypes(courseID);
  }, [courseID]);

  if (!calendarEntryTypes || !hasLoadedCalendarEntryTypesForCourse(courseID) || !course)
    return <LoadingComponent content="Loading calendar entry types..." />;

  const initialValues: CalendarEntryModalFormValues = {
    dayOfCalendarEntry: calendarEntryToEdit?.startTime,
    startTime: calendarEntryToEdit?.startTime,
    endTime: calendarEntryToEdit?.endTime,
    recurUntilDate: undefined,
    title: calendarEntryToEdit?.title ?? "",
    description: calendarEntryToEdit?.description ?? "",
    location: calendarEntryToEdit?.location ?? "",
    isRecurring: false,
    isCanceled: !!calendarEntryToEdit?.isCanceled,
    isRequired: calendarEntryToEdit?.isRequired !== false, // set isRequired to true by default
    calendarEntryLinks: [...(calendarEntryToEdit?.links ?? [])].map((link) => ({ ...link })), // map values to clone them
    calendarEntryTypes: calendarEntryToEdit?.calendarEntryTypes.map(({ id }) => id) ?? [],
    newEventTypeName: "",
    newEventTypeColor: getRandomColor(Color.GREY),
    newLink: "",
    daysToRecur: [],
  };

  const setDateMonthYear = (dateToUpdate: Date | undefined, source: Date | undefined) => {
    const dateClone = dateToUpdate ? new Date(dateToUpdate) : undefined;
    dateClone?.setDate(source?.getDate() ?? 1);
    dateClone?.setMonth(source?.getMonth() ?? 1);
    dateClone?.setFullYear(source?.getFullYear() ?? 1970);
    return dateClone;
  };

  const getUpdatedCalendarEntryTypeArrayAfterClick = (
    calendarEntryType: CalendarEntryType,
    selectedCalendarEntryTypes: string[]
  ) => {
    const formCalendarEntryTypes = [...selectedCalendarEntryTypes];
    const index = formCalendarEntryTypes.indexOf(calendarEntryType.id);

    if (index === -1) formCalendarEntryTypes.push(calendarEntryType.id);
    else formCalendarEntryTypes.splice(index, 1);

    return formCalendarEntryTypes;
  };

  const onDayOfCalendarEntryChange = (
    date: Date,
    setValues: FormikSetValuesFunction<CalendarEntryModalFormValues>,
    values: CalendarEntryModalFormValues
  ) => {
    const startTime = setDateMonthYear(values.startTime, date);
    const endTime = setDateMonthYear(values.endTime, date);

    setValues({ ...values, dayOfCalendarEntry: date, startTime, endTime });
  };

  const onStartTimeDateChange = (
    date: Date,
    setValues: FormikSetValuesFunction<CalendarEntryModalFormValues>,
    values: CalendarEntryModalFormValues
  ) => {
    const currentStartTime = values.startTime;
    const currentEndTime = values.endTime;

    const newDate = setDateMonthYear(date, values.dayOfCalendarEntry) as Date;

    if (currentStartTime && currentEndTime && newDate.getTime() > currentEndTime.getTime()) {
      setValues({ ...values, startTime: newDate, endTime: values.dayOfCalendarEntry });
    } else if (!currentEndTime) {
      setValues({ ...values, startTime: newDate, endTime: newDate });
    } else {
      setValues({ ...values, startTime: newDate });
    }
  };

  const onEndTimeDateChange = (date: Date, setFieldValue: FormikSetFieldValueFunction) => {
    setFieldValue("endTime", date);
  };

  const handleFormSubmit = async (values: CalendarEntryModalFormValues) => {
    const dayOf = values.dayOfCalendarEntry as Date;
    const startTime = setDateMonthYear(values.startTime, dayOf) as Date;
    const endTime = setDateMonthYear(values.endTime, dayOf) as Date;
    const links = values.calendarEntryLinks;

    links.forEach((link) => {
      // eslint-disable-next-line no-param-reassign
      link.calendarLinkType = undefined;
    });

    const calendarEntry: CalendarEntry = {
      id: calendarEntryToEdit?.id ?? emptyID,
      calendarEntryTypes: values.calendarEntryTypes.map(
        (typeID) =>
          calendarEntryTypes.find((entryType) => entryType.id === typeID) as CalendarEntryType
      ),
      courseID,
      description: values.description,
      startTime,
      endTime,
      isCanceled: values.isCanceled,
      isRequired: values.isRequired,
      links,
      location: values.location,
      title: values.title,
    };

    setIsSubmittingForm(true);

    const allCalendarEntriesToCreate = createRecurringCalendarEntries(
      calendarEntry,
      values.daysToRecur,
      values.recurUntilDate
    );

    const result = await createOrUpdateCalendarEntries(courseID, allCalendarEntriesToCreate, {
      customHandler: () =>
        toastStore.showToast("Something went wrong when creating the calendar event...", {
          color: "red",
        }),
    });

    if (result) {
      modalStore.closeModal();
      toastStore.showToast(
        `Successfully ${calendarEntryToEdit ? "updated" : "created"} calendar event`
      );
    }

    setIsSubmittingForm(false);
  };

  const isThisFormDirty = (
    initValues: CalendarEntryModalFormValues,
    values: CalendarEntryModalFormValues
  ) => {
    const iv = { ...initValues };
    const v = { ...values };

    // There is a case where the new event type colors are randomized, making the form "dirty" when it isn't.
    // Set the values equal to each other to prevent this bug.
    iv.newEventTypeColor = Color.BLUE;
    v.newEventTypeColor = Color.BLUE;

    return isFormDirty(iv, v);
  };

  const formTitle = calendarEntryToEdit ? "Update Calendar Event" : "Create Calendar Event";

  const validate = (
    values: CalendarEntryModalFormValues
  ): FormikErrors<CalendarEntryModalFormValues> => {
    const errors: FormikErrors<CalendarEntryModalFormValues> = {};

    if (!values.title) {
      errors.title = "The calendar event must not be empty";
    }

    if (!values.startTime) errors.startTime = "You must specify a start time for this event";

    if (!values.endTime) errors.endTime = "You must specify an end time for this event";

    if (!values.dayOfCalendarEntry)
      errors.dayOfCalendarEntry = "You must specify the day in which this event will occur";

    if (values.startTime && values.endTime && values.startTime.getTime() > values.endTime.getTime())
      errors.endTime = "The end time of this event must occur after the start time";

    if (values.calendarEntryTypes.length === 0)
      errors.calendarEntryTypes = "You must have at least one calendar entry type";

    if (values.isRecurring) {
      if (values.daysToRecur.length === 0) {
        errors.daysToRecur = "You must select at least one day this event should recur on";
      }

      if (!values.recurUntilDate) {
        errors.recurUntilDate = "You must select a date in which this event will stop recurring";
      }
    }

    return errors;
  };

  const getSubmitButtonTitle = (values: CalendarEntryModalFormValues) => {
    if (
      values.isRecurring &&
      values.recurUntilDate &&
      values.daysToRecur.length > 0 &&
      values.startTime
    ) {
      const mockCalendarEntry = {
        id: emptyID,
        startTime: values.startTime,
        endTime: values.endTime,
      } as CalendarEntry;

      const recurringCalendarEntries = createRecurringCalendarEntries(
        mockCalendarEntry,
        values.daysToRecur,
        values.recurUntilDate
      );

      return `Create ${recurringCalendarEntries.length} Calendar Event${
        recurringCalendarEntries.length === 1 ? "" : "s"
      }`;
    }

    return formTitle;
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validate={validate}
        validateOnChange={true}
      >
        {({ values, setFieldValue, errors, touched, setFieldTouched, setValues }) => (
          <Form className="CreateOrUpdateCalendarEntryModal">
            <Header as="h2" content={formTitle} />
            <FormLogoInput
              iconName="calendar plus outline"
              label="Name This Calendar Event"
              name="title"
              placeholder="Enter calendar event title..."
              required={true}
              type="text"
              error={touched.title && errors.title}
            />
            <Header as="h4" content="Event Timing:" className="no-bottom-margin" />
            <div className="date-pickers">
              <FormDateInput
                currentDate={values.dayOfCalendarEntry}
                onDateChange={(newDate: Date) => {
                  onDayOfCalendarEntryChange(newDate, setValues, values);
                  setFieldTouched("dayOfCalendarEntry", true, false);
                }}
                icon="calendar alternate outline"
                label="Select Day of Event:"
                required={true}
                datePickerProps={{
                  dateFormat: "dd/MM/YYY",
                }}
                error={touched.dayOfCalendarEntry && errors.dayOfCalendarEntry}
              />
              <VerticalGap height="0.5em" />
              <FormDateInput
                disabled={!values.dayOfCalendarEntry}
                currentDate={values.startTime}
                onDateChange={(newDate: Date) => {
                  onStartTimeDateChange(newDate, setValues, values);
                  setFieldTouched("startTime", true, false);
                }}
                icon="calendar alternate outline"
                label="Start Time:"
                required={true}
                datePickerProps={{
                  dateFormat: "hh:mm a",
                  minDate: values.dayOfCalendarEntry,
                  maxDate: values.dayOfCalendarEntry,
                  shouldCloseOnSelect: true,
                  showTimeInput: true,
                  showTimeSelectOnly: true,
                }}
                error={touched.startTime && errors.startTime}
              />
              <VerticalGap height="0.5em" />
              <FormDateInput
                disabled={!values.dayOfCalendarEntry || !values.startTime}
                currentDate={values.endTime}
                onDateChange={(newDate: Date) => {
                  onEndTimeDateChange(newDate, setFieldValue);
                  setFieldTouched("endTime", true, false);
                }}
                icon="calendar alternate outline"
                label="End Time:"
                required={true}
                datePickerProps={{
                  dateFormat: "hh:mm a",
                  minDate: values.dayOfCalendarEntry,
                  maxDate: values.dayOfCalendarEntry,
                  minTime: values.startTime,
                  shouldCloseOnSelect: true,
                  showTimeInput: true,
                  showTimeSelectOnly: true,
                }}
                error={(touched.endTime || touched.startTime) && errors.endTime}
              />
            </div>
            <VerticalGap height="1em" />
            <Checkbox
              toggle
              name={"isCanceled"}
              label="Event is canceled"
              onChange={(e, { checked }) => setFieldValue("isCanceled", checked)}
              checked={values.isCanceled}
            />
            <VerticalGap height="0.5em" />
            <Checkbox
              toggle
              name={"isRequired"}
              label="Event is required for all students"
              onChange={(e, { checked }) => setFieldValue("isRequired", checked)}
              checked={values.isRequired}
            />
            <VerticalGap height="0.5em" />
            {calendarEntryToEdit === undefined && (
              <>
                <Checkbox
                  toggle
                  name={"isRecurring"}
                  label="Is this a recurring event?"
                  onChange={(e, { checked }) => setFieldValue("isRecurring", checked)}
                  checked={values.isRecurring}
                />
                {values.isRecurring && (
                  <>
                    <VerticalGap height="1em" />
                    <CalendarEntryRecurrenceEditor
                      recurUntilDate={values.recurUntilDate}
                      daysToRecur={values.daysToRecur}
                      maxDate={course?.endDate}
                      minDate={values.startTime}
                      setFieldValue={setFieldValue}
                    />
                  </>
                )}
              </>
            )}
            <Header content="Event Types:" as="h4" className="no-bottom-margin" />
            <FlexContainer flexWrap="wrap" gap="0.5em" alignItems="center" className="event-types">
              {calendarEntryTypes
                .filter(({ isAssignmentEntryType }) => !isAssignmentEntryType)
                .map((calendarEntryType) => (
                  <Label
                    tag
                    role="button"
                    content={calendarEntryType.name}
                    color={
                      values.calendarEntryTypes.includes(calendarEntryType.id)
                        ? (calendarEntryType.color as SemanticCOLORS)
                        : undefined
                    }
                    key={calendarEntryType.id}
                    onClick={() => {
                      setFieldValue(
                        "calendarEntryTypes",
                        getUpdatedCalendarEntryTypeArrayAfterClick(
                          calendarEntryType,
                          values.calendarEntryTypes
                        )
                      );
                      setFieldTouched("calendarEntryTypes", true, false);
                    }}
                  />
                ))}
              {isAddingNewEventType ? (
                <FlexContainer gap="0.5em" flexWrap="wrap">
                  <Field
                    name="newEventTypeName"
                    className="new-event-type-input"
                    placeholder="Enter event type name..."
                  />
                  <ColorPickerInput
                    currentColor={values.newEventTypeColor}
                    onColorSelect={(newColor) => setFieldValue("newEventTypeColor", newColor)}
                    childModalIndex={1}
                  />
                  <Button
                    color="green"
                    icon="plus"
                    content="Add Event Type"
                    disabled={!values.newEventTypeName}
                    onClick={async () => {
                      setIsSubmittingNewEventType(true);

                      const savedEntryType = await createOrUpdateCalendarEntryType({
                        courseID,
                        name: values.newEventTypeName,
                        id: emptyID,
                        color: values.newEventTypeColor,
                      });

                      setIsSubmittingNewEventType(false);
                      setAddingNewEventType(false);

                      const newValues: CalendarEntryModalFormValues = { ...values };

                      newValues.newEventTypeColor = getRandomColor(Color.GREY);
                      newValues.newEventTypeName = "";

                      if (savedEntryType) {
                        newValues.calendarEntryTypes = getUpdatedCalendarEntryTypeArrayAfterClick(
                          savedEntryType,
                          values.calendarEntryTypes
                        );
                      }

                      setValues(newValues);
                      setFieldTouched("calendarEntryTypes", true, false);
                    }}
                    type="button"
                    loading={isSubmittingNewEventType}
                  />
                  <Button
                    color="red"
                    icon="x"
                    content="Cancel"
                    onClick={() => {
                      setAddingNewEventType(false);
                      setFieldValue("newEventTypeName", "");
                      setFieldTouched("calendarEntryTypes", true, false);
                    }}
                  />
                </FlexContainer>
              ) : (
                <Label
                  content="Add event Type"
                  icon="plus"
                  tag
                  role="button"
                  onClick={() => setAddingNewEventType(true)}
                />
              )}
            </FlexContainer>
            {touched.calendarEntryTypes && errors.calendarEntryTypes && (
              <span className="calendar-entry-type-error">{errors.calendarEntryTypes}</span>
            )}
            <VerticalGap height="1em" />
            <FormLogoInput
              iconName="map outline"
              label="Location"
              name="location"
              placeholder="Enter event location..."
              required={false}
              type="text"
            />
            <VerticalGap height="1.5em" />
            <FormRichTextInput
              label="Description"
              placeholder="Enter event description..."
              defaultValue={values.description}
              required={false}
              onChange={(value) => setFieldValue("description", value)}
            />
            <VerticalGap height=".5em" />
            <CalendarEntryLinkEditor
              courseID={courseID}
              currentCalendarEntryLinks={values.calendarEntryLinks}
              setFieldValue={setFieldValue}
              calendarEntryLinksFieldValueName={"calendarEntryLinks"}
              calendarEntry={calendarEntryToEdit}
              newLinkFieldValueName="newLink"
              currentNewLink={values.newLink}
              setValues={setValues}
              values={values}
            />
            <VerticalGap height="1em" />
            <Button
              icon="cancel"
              content="Cancel"
              type="button"
              color="red"
              onClick={() => {
                if (isThisFormDirty(initialValues, values)) {
                  const close = window.confirm(
                    "You have unsaved changes. Are you sure you want to close this form?"
                  );

                  if (close) modalStore.closeModal();
                } else {
                  modalStore.closeModal();
                }
              }}
            />
            <Button
              icon={calendarEntryToEdit ? "save" : "plus"}
              disabled={
                !isThisFormDirty(initialValues, values) || (errors && objectSize(errors) !== 0)
              }
              content={getSubmitButtonTitle(values)}
              type="submit"
              color="blue"
              floated="right"
              loading={isSubmittingForm}
            />
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default observer(CreateOrUpdateCalendarEntryModal);

import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Container, Header, Icon } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../hooks/useValidParameters";
import { Assignment } from "../../../../models/Assignment";
import { Objective } from "../../../../models/Objective";
import { useStore } from "../../../../stores/store";
import { formatDateDownToMinutes } from "../../../../utilities/dateTimeUtils";
import LoadingComponent from "../../../../utilities/routing/components/LoadingComponent";
import { SubmissionPackage } from "../../../../utilities/submissionUtils";
import UserCard from "../../../_common/cards/UserCard";
import HorizontalIndent from "../../../_common/style/spacing/HorizontalIndent";
import VerticalGap from "../../../_common/style/spacing/VerticalGap";
import "./AssignmentSubmission.css";
import AssignmentSubmissionContents from "./AssignmentSubmissionContents";
import "./AssignmentSubmissionForm.css";

interface AssignmentSubmissionProps {
  submissionPackage: SubmissionPackage;
  assignment: Assignment<Objective>;
  setEditing?: (editing: boolean) => void;
  showTeachingTeamUploadedFiles?: boolean;
}

const AssignmentSubmission: React.FC<AssignmentSubmissionProps> = ({
  submissionPackage,
  assignment,
  setEditing,
  showTeachingTeamUploadedFiles = true,
}) => {
  const { courseID } = useValidParams<{
    courseID: string;
    assignmentID: string;
  }>();
  const { isUserStudent, isUserTeachingTeam } = useCurrentUserCourseRole(courseID);

  const { courseStore } = useStore();
  const { roster, loadCurrentCourseRoster, hasLoadedRoster } = courseStore;

  useEffect(() => {
    loadCurrentCourseRoster(courseID);
  }, [courseID]);

  if (!roster || !hasLoadedRoster(courseID))
    return <LoadingComponent content="Loading student information..." />;

  const student = roster?.find((cm) => cm.userID === submissionPackage.userID);

  const handleEditSubmissionButtonClicked = () => {
    let confirm = true;
    if (submissionPackage.willBeLateIfEdited && !submissionPackage.isSubmissionAlreadyLate) {
      confirm = window.confirm("CAUTION! If you edit this submission, it will be marked as late!");
    }

    if (confirm && setEditing !== undefined) setEditing(true);
  };

  return (
    <div className="AssignmentSubmission">
      <Header as="h3" className="submission-details-header">
        Submission Details:
      </Header>
      <HorizontalIndent>
        {isUserTeachingTeam && student !== undefined && (
          <>
            <UserCard user={student} key={submissionPackage.userID} backgroundColor="grey" />
            <VerticalGap height="1em" />
          </>
        )}
        <Container className="submission-details">
          Status:{" "}
          {submissionPackage.isDraft && assignment.dueDate && assignment.dueDate < new Date() && (
            <span className="late-reminder">
              <span className="unsubmitted-draft">Unsubmitted Draft</span> (Instructors cannot see
              this submission or its contents when assessing.)
            </span>
          )}
          {!submissionPackage.isDraft &&
            submissionPackage.submission?.isAutoGenerated &&
            "Automatically Generated"}
          {submissionPackage.isDraft &&
            (!assignment.dueDate || assignment.dueDate >= new Date()) &&
            "Draft"}
          {!submissionPackage.isDraft &&
            !submissionPackage.submission?.isAutoGenerated &&
            "Successfully Submitted"}
        </Container>
        {!submissionPackage.submission?.isAutoGenerated && (
          <Container className="submission-details">
            {submissionPackage.isDraft && submissionPackage.submission?.createdAt
              ? "Saved At: "
              : "Submitted At: "}{" "}
            {submissionPackage &&
              submissionPackage.submission?.createdAt &&
              formatDateDownToMinutes(submissionPackage && submissionPackage.submission.createdAt)}
            {isUserStudent && submissionPackage.isEditable && (
              <div
                role={"button"}
                className={"ui default-button edit-submission-button"}
                onClick={() => handleEditSubmissionButtonClicked()}
              >
                <Icon name={"edit"} />
                Edit
              </div>
            )}
          </Container>
        )}
      </HorizontalIndent>
      {
        <AssignmentSubmissionContents
          submissionPackage={submissionPackage}
          showTeachingTeamUploadedFiles={showTeachingTeamUploadedFiles}
        />
      }
    </div>
  );
};

export default observer(AssignmentSubmission);

import { observer } from "mobx-react-lite";
import { Dropdown, Icon, Loader } from "semantic-ui-react";
import useCurrentUserCourseRole from "../../../../hooks/useCurrentUserCourseRole";
import useValidParams from "../../../../hooks/useValidParameters";
import { UploadedFile } from "../../../../models/UploadedFile";
import { useStore } from "../../../../stores/store";
import {
  createClassName,
  getFileExtension,
  isValidFileName,
  isValidID,
} from "../../../../utilities/utils";
import "../../../_common/file/FileLister.css";

interface CourseFileProps {
  file: UploadedFile;
}

const CourseFile: React.FC<CourseFileProps> = ({ file }) => {
  const { uploadedCourseFileStore, toastStore, modalStore } = useStore();
  const {
    downloadCourseFile,
    updateCourseFile,
    deleteCourseFile,
    mostRecentlyUploadedCourseFileID,
  } = uploadedCourseFileStore;
  const { courseID } = useValidParams<{ courseID: string }>();

  const { isUserTeachingTeam, isUserStudent } = useCurrentUserCourseRole(courseID);

  const validateNewFileName = (input: string) => {
    const originalExtension = getFileExtension(file.originalFileName);
    if (!isValidFileName(input, originalExtension)) {
      return `Invalid File Name. Use only Aa-Zz, 0-9, spaces, dashes, and underscores. The new file name must end with ".${originalExtension}".`;
    }
    return true;
  };

  const handleEditFileClick = async () => {
    const newFileName = await modalStore.openInputDialogModal(
      <>
        Enter the new name for {file.originalFileName}.<br />
        Don't forget the file extension (.{getFileExtension(file.originalFileName)}).
      </>,
      {
        confirmButtonText: "Rename File",
        validateInput: validateNewFileName,
        defaultValue: file.originalFileName,
      }
    );

    if (newFileName) {
      const newFile: UploadedFile = { ...file };
      newFile.originalFileName = newFileName;
      await updateCourseFile(newFile);
      toastStore.showToast(`Successfully renamed file to ${newFile?.originalFileName}`);
    }
  };

  const handleDeleteFileClick = async () => {
    if (
      window.confirm(`Are you sure you want to delete ${file.originalFileName}?`) &&
      file.id &&
      file.courseID
    ) {
      await deleteCourseFile(file.id, file.courseID);
      toastStore.showToast(`Successfully deleted ${file.originalFileName}`);
    }
  };

  const dropdownOptions = [
    {
      key: "copy",
      content: "Rename File",
      icon: "pencil",
      onClick: async () => handleEditFileClick(),
    },
    {
      key: "delete",
      content: "Delete This File",
      icon: "trash",
      onClick: () => handleDeleteFileClick(),
    },
  ];

  // there are files to display
  return (
    <div
      className={createClassName("existing-file", {
        name: "newest-item",
        apply: file.id === mostRecentlyUploadedCourseFileID,
      })}
      role="button"
      onClick={() => {
        downloadCourseFile(file);
      }}
    >
      <div className="file-meta">
        <Icon name="file outline" />
        {file.originalFileName}
      </div>
      {isValidID(file.id) && isUserTeachingTeam && (
        <Dropdown
          className="options icons"
          icon={"ellipsis vertical"}
          options={dropdownOptions}
          direction="left"
        />
      )}
      {isValidID(file.id) && isUserStudent && (
        <div className="icons">
          <Icon name="download" />
        </div>
      )}
      {!isValidID(file.id) && <Loader active={true} size="small" inline />}
    </div>
  );
};
export default observer(CourseFile);

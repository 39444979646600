// eslint-disable-next-line import/no-cycle
import api, { ErrorHandlerPackage } from "../api/api";
import { Assignment } from "../models/Assignment";
import { AssignmentType } from "../models/AssignmentType";
import { Objective } from "../models/Objective";
import { PollingAssignmentAssessmentRule } from "../models/PollingAssignmentAssessmentRule";
import { deepCopy } from "../utilities/collectionUtils";
import { compareDates, getLocalDate, getUTCDate } from "../utilities/dateTimeUtils";
import { emptyID } from "../utilities/submissionUtils";
import PersistentPreferenceStore from "./persistentPreferenceStore";
import { store } from "./store";
import { StoreValue } from "./storeValue";

export default class AssignmentStore {
  private assignmentsByCourseRegistry = new StoreValue<Map<string, Assignment<Objective>>>();

  private assignmentWithObjectivesAndMasteryLevelsRegistry = new StoreValue<
    Assignment<Objective>,
    { assignmentID: string }
  >();

  private assignmentsByObjectiveRegistry = new StoreValue<Map<string, Assignment<Objective>[]>>();

  private assignmentTypesRegistry = new StoreValue<AssignmentType[]>();

  private assessmentSummarySymbolRegistry = new StoreValue<Map<string, string>>();

  private pollingAssignmentAssessmentRulesForAssignmentRegistry = new StoreValue<
    PollingAssignmentAssessmentRule[],
    { assignmentID: string }
  >();

  private creatingDraftAssignment = false;

  private creatingUpdatingAssignment = false;

  private preferenceStore: PersistentPreferenceStore;

  constructor(preferenceStore: PersistentPreferenceStore) {
    this.preferenceStore = preferenceStore;
  }

  hasLoadedPollingAssignmentAssessmentRulesForAssignment = (assignmentID: string) =>
    this.pollingAssignmentAssessmentRulesForAssignmentRegistry.fresh(false, { assignmentID });

  hasLoadedAssignmentsByCourse = () =>
    !this.assignmentsByCourseRegistry.isLoading() && this.assignmentsByCourseRegistry.fresh(false);

  hasLoadedAssignmentWithObjectivesAndMasteryLevels = (assignmentID: string) =>
    !this.assignmentWithObjectivesAndMasteryLevelsRegistry.isLoading({ assignmentID }) &&
    this.assignmentWithObjectivesAndMasteryLevelsRegistry.fresh(false, { assignmentID });

  hasLoadedAssignmentsByObjective = () =>
    !this.assignmentsByObjectiveRegistry.isLoading() &&
    this.assignmentsByObjectiveRegistry.fresh(false);

  hasLoadedAssignmentTypes = () =>
    !this.assignmentTypesRegistry.isLoading() && this.assignmentTypesRegistry.fresh(false);

  reset = () => {
    this.assignmentsByCourseRegistry.reset();
    this.assignmentsByObjectiveRegistry.reset();
    this.assignmentWithObjectivesAndMasteryLevelsRegistry.reset();
    this.assignmentTypesRegistry.reset();
    this.pollingAssignmentAssessmentRulesForAssignmentRegistry.reset();
  };

  get pollingAssignmentAssessmentRulesForAssignment() {
    return this.pollingAssignmentAssessmentRulesForAssignmentRegistry.value;
  }

  get assignmentsByCourse() {
    return (
      this.assignmentsByCourseRegistry.value &&
      Array.from(this.assignmentsByCourseRegistry.value.values())
    );
  }

  get assignmentWithObjectivesAndMasteryLevels() {
    return this.assignmentWithObjectivesAndMasteryLevelsRegistry.value;
  }

  get assignmentTypes() {
    return this.assignmentTypesRegistry.value;
  }

  assignmentsByObjective = (objectiveID: string) =>
    this.assignmentsByObjectiveRegistry.value &&
    this.assignmentsByObjectiveRegistry.value.get(objectiveID);

  loadAssignmentsByCourse = async (courseID: string) => {
    if (this.assignmentsByCourseRegistry.fresh(true)) return;

    this.assignmentsByCourseRegistry.setLoading(true);

    const assignments = await api.Assignments.listForCourse(courseID);

    this.assignmentsByCourseRegistry.setValue(new Map<string, Assignment<Objective>>());
    assignments.forEach((a: Assignment<Objective>) => {
      const assignment = a;
      this.setAssignmentDates(assignment);
      this.assignmentsByCourseRegistry.ifPresent((v) => v.set(assignment.id, assignment));

      if (assignment.graphicComponents !== undefined) {
        this.addAssessmentSummarySymbols(assignment.graphicComponents);
      }
    });

    this.assignmentsByCourseRegistry.setLoading(false);
  };

  loadAssignmentsByObjective = async (objectiveID: string, courseID: string) => {
    if (
      this.assignmentsByObjectiveRegistry.fresh(true) ||
      this.assignmentsByObjectiveRegistry.test((value) => value.has(objectiveID))
    )
      return;

    this.assignmentsByObjectiveRegistry.setLoading(true);

    const assignments = await api.Assignments.listForObjective(objectiveID, courseID);

    this.assignmentsByObjectiveRegistry.setValueIfAbsent(
      () => new Map<string, Assignment<Objective>[]>()
    );
    this.assignmentsByObjectiveRegistry.ifPresent((v) => v.set(objectiveID, assignments));

    this.assignmentsByObjectiveRegistry.setLoading(false);
  };

  loadAssignmentWithObjectivesAndMasteryLevels = async (assignmentID: string, courseID: string) => {
    if (this.assignmentWithObjectivesAndMasteryLevelsRegistry.fresh(true, { assignmentID })) return;

    this.assignmentWithObjectivesAndMasteryLevelsRegistry.setLoading(true, { assignmentID });

    const assignment = await api.Assignments.details(assignmentID, courseID);

    this.setAssignmentDates(assignment);

    if (assignment.graphicComponents !== undefined) {
      this.addAssessmentSummarySymbols(assignment.graphicComponents);
    }

    this.assignmentWithObjectivesAndMasteryLevelsRegistry.setAll(assignment, { assignmentID });

    this.assignmentWithObjectivesAndMasteryLevelsRegistry.setLoading(false);
  };

  loadAssignmentTypes = async (courseID: string) => {
    if (this.assignmentTypesRegistry.fresh(true)) return;

    this.assignmentTypesRegistry.setLoading(true);

    const assignmentTypes = await api.AssignmentTypes.listForCourse(courseID);

    this.assignmentTypesRegistry.setValue(assignmentTypes);

    this.preferenceStore.setAssignmentTypes(assignmentTypes);

    this.assignmentTypesRegistry.setLoading(false);
  };

  loadPollingAssignmentAssessmentRulesForAssignment = async (
    courseID: string,
    assignmentID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    if (this.pollingAssignmentAssessmentRulesForAssignmentRegistry.fresh(true, { assignmentID })) {
      return;
    }

    this.pollingAssignmentAssessmentRulesForAssignmentRegistry.setLoading(true, { assignmentID });

    const result = await api.PollingAssignmentAssessmentRules.list(
      courseID,
      assignmentID,
      errorHandlerPackage
    );

    this.pollingAssignmentAssessmentRulesForAssignmentRegistry.setAll(result, { assignmentID });

    this.pollingAssignmentAssessmentRulesForAssignmentRegistry.setLoading(false);
  };

  createOrUpdatePollingAssignmentAssessmentRule = (
    pollingAssignmentAssessmentRule: PollingAssignmentAssessmentRule,
    errorHandlerPackage?: ErrorHandlerPackage
  ) =>
    api.PollingAssignmentAssessmentRules.createOrUpdate(
      pollingAssignmentAssessmentRule,
      errorHandlerPackage
    );

  deletePollingAssignmentAssessmentRule = (
    courseID: string,
    ruleID: string,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => api.PollingAssignmentAssessmentRules.delete(courseID, ruleID, errorHandlerPackage);

  createOrUpdateAssignment = async (assignment: Assignment<Objective>) => {
    if (this.creatingUpdatingAssignment) {
      return undefined;
    }

    this.creatingUpdatingAssignment = true;

    // convert the dates to UTC, then call api
    const a = assignment;

    a.dueDate = a.dueDate ? getUTCDate(a.dueDate) : a.dueDate;

    a.resubmissionDueDate = a.resubmissionDueDate
      ? getUTCDate(a.resubmissionDueDate)
      : a.resubmissionDueDate;
    const updatedAssignmentPromise = api.Assignments.createOrUpdate(assignment);

    this.creatingUpdatingAssignment = false;

    // update other stores based on this value
    updatedAssignmentPromise.then((updatedAssignment): void => {
      let oldAssignment: Assignment<Objective> | undefined;

      // get the old assignment, if it exists
      if (this.assignmentsByCourseRegistry.value?.has(updatedAssignment.id)) {
        oldAssignment = this.assignmentsByCourseRegistry.value.get(updatedAssignment.id);
      }

      this.setAssignmentDates(updatedAssignment);

      // if the old assignment does exist, update the assignments by course registry
      if (this.assignmentsByCourseRegistry.value && oldAssignment) {
        // make a copy of the registry value
        const newAssignmentsByCourseMap = new Map(this.assignmentsByCourseRegistry.value);

        // add the new assignment to the map
        newAssignmentsByCourseMap.set(updatedAssignment.id, updatedAssignment);

        // set the registry's value
        this.assignmentsByCourseRegistry.setValue(newAssignmentsByCourseMap);
      }

      // if the old assignment does exist, remove old course from assignments by objective registry
      if (this.assignmentsByObjectiveRegistry.value && oldAssignment) {
        const newAssignmentsByObjectives = deepCopy(this.assignmentsByObjectiveRegistry.value);

        // for each objective in the old assignment, remove the assignment
        oldAssignment.objectives?.forEach((objective) => {
          const listOfObjectives = newAssignmentsByObjectives.get(objective.id);
          if (listOfObjectives) {
            // filter the objectives so the assignment is removed
            const newFilteredList = listOfObjectives.filter(
              (objectiveAssignment: Assignment<Objective>) =>
                objectiveAssignment.id !== updatedAssignment.id
            );

            // set this new filtered list as the list of assignments for this objective
            newAssignmentsByObjectives.set(objective.id, newFilteredList);
          }
        });

        // for each objective in the new assignment, add the assignment
        updatedAssignment.objectives?.forEach((objective) => {
          const listOfAssignments = newAssignmentsByObjectives.get(objective.id);
          if (listOfAssignments) {
            // add the assignment to the list
            listOfAssignments.push(updatedAssignment);

            // ensure they're still sorted by due date
            const newSortedList = listOfAssignments.sort(
              (assignmentA: Assignment<Objective>, assignmentB: Assignment<Objective>) =>
                compareDates(assignmentA.dueDate, assignmentB.dueDate)
            );

            // set this new sorted list as the list of assignments for this objective
            newAssignmentsByObjectives.set(objective.id, newSortedList);
          }

          // this objective wasn't listed in the original map, so we'll add it
          else {
            newAssignmentsByObjectives.set(objective.id, [updatedAssignment]);
          }
        });

        // update the registry with the updated map
        this.assignmentsByObjectiveRegistry.setValue(newAssignmentsByObjectives);
      }

      // update the hierarchical objectives with assignments registry in objectives store
      store.objectiveStore.updateAssignmentInHierarchicalObjectivesWithAssignments(
        updatedAssignment,
        oldAssignment
      );
    });

    return updatedAssignmentPromise;
  };

  get assessmentSummarySymbols() {
    return this.assessmentSummarySymbolRegistry.value;
  }

  newAssignment = async (a: Assignment<Objective>) => api.Assignments.createOrUpdate(a);

  deleteAssignment = async (courseID: string, assignmentID: string) => {
    const isSuccess = await api.Assignments.delete(courseID, assignmentID);
    if (isSuccess) {
      store.objectiveStore.deleteAssignmentInHierarchicalObjectivesWithAssignments(assignmentID);
      this.reset();
    }
  };

  createOrUpdateAssignmentType = async (
    assignmentType: AssignmentType,
    errorHandlerPackage?: ErrorHandlerPackage
  ) => {
    const result = await api.AssignmentTypes.createOrUpdate(assignmentType, errorHandlerPackage);

    if (result) {
      this.preferenceStore.addOrUpdateAssignmentType(result);

      const newAssignmentTypes = [...(this.assignmentTypesRegistry.value ?? [])];
      const index = newAssignmentTypes.findIndex((at) => at.id === result.id);

      if (index === -1) newAssignmentTypes.push(result);
      else newAssignmentTypes[index] = result;

      this.assignmentTypesRegistry.setValue(newAssignmentTypes);

      return result;
    }

    return undefined;
  };

  createDraftAssignment = async (courseID: string) => {
    if (this.creatingDraftAssignment) {
      return undefined;
    }

    this.creatingDraftAssignment = true;

    const assignment: Assignment<Objective> = {
      id: emptyID,
      courseID,
      isDraft: true,
      requestAssignmentSurvey: false,
      hasUploadedFiles: false,
    };

    const result = await this.newAssignment(assignment);

    this.creatingDraftAssignment = false;

    return result;
  };

  addAssessmentSummarySymbols = (summarySymbols: Map<string, string> | undefined) => {
    if (summarySymbols === undefined) {
      return;
    }

    const summarySymbolsMap = new Map(Object.entries(summarySymbols));
    if (summarySymbolsMap === undefined || summarySymbolsMap.size === undefined) {
      return;
    }

    summarySymbolsMap.forEach((value, key) => {
      this.addAssessmentSummarySymbol(key, value);
    });
  };

  addAssessmentSummarySymbol = (key: string, symbol: string) => {
    this.assessmentSummarySymbolRegistry.setValueIfAbsent(() => new Map<string, string>());
    if (!this.assessmentSummarySymbolRegistry.value?.has(key)) {
      this.assessmentSummarySymbolRegistry.value?.set(key, symbol);
    }
  };

  setAssignmentDates(a: Assignment<Objective>) {
    const assignment = a;
    assignment.dueDate = assignment.dueDate ? getLocalDate(assignment.dueDate) : assignment.dueDate;
    if (assignment.resubmissionDueDate) {
      assignment.resubmissionDueDate = getLocalDate(assignment.resubmissionDueDate);
    }
    if (assignment.acceptSubmissionsUntilDate) {
      assignment.acceptSubmissionsUntilDate = getLocalDate(assignment.acceptSubmissionsUntilDate);
    }
    if (assignment.releaseDate) {
      assignment.releaseDate = getLocalDate(assignment.releaseDate);
    }
  }
}
